import type { FilterHeader, FilterValue, SortItem } from "~~/components/datatable/DataTableTypes"

export enum FilterAvailability {
  ORGANIZATION_AND_SUPPLIERS = "ORGANIZATION_AND_SUPPLIERS",
  ORGANIZATION = "ORGANIZATION",
  PRIVATE = "PRIVATE",
  SUPPLIER_GLOBAL = "SUPPLIER_GLOBAL"
}


export const assetTableKeys = ["ASSETS", "EXTERNAL_KEYS", "MEASUREMENTS", "RELATIONS"] as const
export const ticketTableKeys = ["TICKETS", "TICKETS_COSTS"] as const
export const analysisTableKeys = ["RISK", "INSPECTION_ANALYSIS", "DATA_QUALITY"] as const
export const financeTableKeys = ["BUDGETS", "CONTRACTS", "COST_SPECIFICATION"] as const
export const sharedTableKeys = ["SHARED_ASSETS", "SHARED_TICKETS"] as const
export const adminTableKeys = ["ADMIN_COMPONENT_TYPES", "ADMIN_TEMPLATES", "ADMIN_LOOKUP_TYPES", "ADMIN_LOOKUP_VALUES", "ADMIN_ORGANIZATIONS", "ADMIN_SECURITY_LOGS", "ADMIN_USERS"] as const
export const mutationLogTableKeys = ["MUTATION_LOG"] as const

export const tableKeys = [
  ...assetTableKeys,
  ...ticketTableKeys,
  ...analysisTableKeys,
  ...financeTableKeys,
  ...sharedTableKeys,
  ...adminTableKeys,
  ...mutationLogTableKeys
]

export type TableKey = typeof tableKeys[number]

export interface FilterPreset {
  _id: string
  description: string
  filterValues: Record<string, FilterValue>
  tableKey: TableKey
  organization: string
  createdBy: { _id: string, description: string }
  createdByOrganization: string
  availability: FilterAvailability,
  headers: Array<MinimalFilterHeader>,
  sortBy?: Array<SortItem>
}

export interface MinimalFilterHeader {
  key: string
  children?: Array<MinimalFilterHeader>
}

export interface FilterPresetPayload extends Pick<FilterPreset, "description" | "filterValues" | "tableKey" | "availability"> { }

export const useFilterPresetStore = defineStore("filter-preset", () => {
  const getAvailableFilterPresetsPerTableKey = async (organization: string, tableKey: string): Promise<Array<FilterPreset>> => {
    const { data } = await useSamApi<Array<FilterPreset>>(`/filter-presets/organization/${organization}/${tableKey}`, { method: "GET" })

    return data
  }

  const getAvailableFilterPresets = async (organization: string): Promise<Array<FilterPreset>> => {
    const { data } = await useSamApi<Array<FilterPreset>>(`/filter-presets/organization/${organization}`, { method: "GET" })

    return data
  }

  const createFilterPreset = async (filterPresetPayload: FilterPresetPayload): Promise<FilterPreset> => {
    const { data } = await useSamApi<FilterPreset>(`/filter-presets`, {
      method: "POST",
      body: JSON.stringify({ filterPreset: filterPresetPayload })
    })

    return data
  }

  const updateFilterPresetDescription = async (filterPresetId: string, filterPresetPayload: FilterPresetPayload): Promise<FilterPreset> => {
    const { data } = await useSamApi<FilterPreset>(`/filter-presets/${filterPresetId}`, {
      method: "PUT",
      body: JSON.stringify({ filterPresetPayload })
    })

    return data
  }

  const deleteFilterPreset = async (filterPresetId: string): Promise<void> => {
    await useSamApi(`/filter-presets/${filterPresetId}`, {
      method: "DELETE"
    })
  }

  return {
    getAvailableFilterPresetsPerTableKey,
    getAvailableFilterPresets,
    createFilterPreset,
    updateFilterPresetDescription,
    deleteFilterPreset,
  }
})